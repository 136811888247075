import React, { useRef, useEffect, useState } from "react";
import cn from "classnames"
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Section } from "@soundtrackyourbrand/ui/src/Section";
import { debounce } from "lib/fns"
import StructuredTextRenderer from "../../StructuredTextRenderer/StructuredTextRenderer";
import Video from "../../Video/Video";
import Personalization from "../../Personalization/Personalization";
import PropTypes from "prop-types";
import * as styles from "./TextImage.module.scss";

const TextImage = ({ block }) => {
  const isToRight = block.imageToTheRight;
  const isStuck = block.stucktotheedge;
  const media = block.media;
  const structuredText = block.textContent;
  const visibleFor = block.visibleFor;
  const mediaRef = useRef(null);
  const [height, setHeight] = useState(0);
  const focalpoint = media?.focalPoint
    ? `${media.focalPoint.x * 100}% ${media.focalPoint.y * 100}%`
    : "50% 50%";

  useEffect(() => {
    if (!isStuck) {
      return
    }

    const handleResize = () => {
      if (mediaRef.current) {
        setHeight(mediaRef.current.offsetHeight);
      }
    };
    setTimeout(() => { handleResize() }, 1);
    const debounceResize = debounce(handleResize, 200)
    window.addEventListener('resize', debounceResize);
    return () => {
      window.removeEventListener('resize', debounceResize);
    };
  }, [isStuck]);

  return (
    <Personalization visibleFor={visibleFor}>
      <Section className={styles.moduleWrapper}>
        <div
          className={cn('row r-middle', styles.row,
            { [styles.rowStuck]: isStuck }
          )}
          style={{
            flexDirection: isToRight
              ? isStuck
                ? "row"
                : "row-reverse"
              : isStuck
                ? "row-reverse"
                : "row",
            minHeight: isStuck ? `${height}px` : "auto",
          }}
        >
          <div
            ref={mediaRef}
            className={
              cn(
                styles.imageWrapper,
                'col sm-12 l-5',
                {
                  'm-5': isStuck,
                  [styles.imageWrapperStuck]: isStuck,
                  [styles.isToRight]: isStuck && isToRight,
                  [styles.isToLeft]: isStuck && !isToRight,
                })
            }
          >
            {media.isImage ? (
              <GatsbyImage image={media.gatsbyImageData} alt={media.alt} className={styles.image} objectPosition={focalpoint} />
            ) : (
              <Video
                src={media.video.mp4Url}
                className={styles.video}
                noControl
              />
            )}
          </div>
          {isStuck ? "" : <div className="col l-1 sm-12"></div>}
          <div className={cn('col l-6 sm-12', { 'm-5': isStuck })}>
            <StructuredTextRenderer
              data={structuredText}
              isParagraphClassName="mb-5 subtle"
              isHeadingClassName="mb-5"
              isListItemClassName="subtle"
            />
          </div>
        </div>
      </Section>
    </Personalization >
  );
};

export default TextImage;

TextImage.propTypes = {
  block: PropTypes.shape({
    imageToTheRight: PropTypes.bool,
    stucktotheedge: PropTypes.bool,
    media: PropTypes.object.isRequired,
    textContent: PropTypes.object.isRequired,
    visibleFor: PropTypes.string.isRequired,
  }),
};

export const query = graphql`
  fragment TextImage on DatoCmsTextimage {
    model {
      apiKey
    }
    id: originalId
    visibleFor
    imageToTheRight
    stucktotheedge
    media {
      isImage
      height
      alt
      gatsbyImageData
      focalPoint {
        x
        y
      }
      format
      video {
        mp4Url(res: low)
      }
    }
    textContent {
      value
      blocks {
        __typename
        ...Link
        ...AppDownload
      }
    }
  }
`;
